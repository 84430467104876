interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export const navItems1: NavData[] = [
  {
    name: 'Dashboard nn',
    url: '/dashboard',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {title: true,
    name: 'Menú Maquinas',
    icon: 'icon-puzzle',
    },
  {
    name: 'Tipos',
    url: '/proveedores/maquinastipos',
    icon: 'icon-puzzle'
  },
  {
    name: 'Maquinas',
    url: '/proveedores/maquinas',
    icon: 'icon-puzzle'
  },
  {
    title: true,
    name: 'Repuestos'
  },
  {
    name: 'Proveedores',
    url: '/proveedores',
    icon: 'icon-puzzle'
  },
  {
    name: 'Repuestos',
    url: '/proveedores/repuestos',
    icon: 'icon-puzzle'
  },
  {
    name: 'Inventario Repuestos',
    url: '/proveedores/inventarios',
    icon: 'icon-puzzle'
  },
  {
    name: 'Compras',
    url: '/proveedores/compras',
    icon: 'icon-puzzle'
  },
  {
    name: 'Cajones',
    url: '/proveedores/cajones',
    icon: 'icon-puzzle'
  },
  {
    name: 'Asignaciones',
    url: '/proveedores/asignaciones',
    icon: 'icon-puzzle'
  },
  {
    name: 'Salidas',
    url: '/proveedores/salida-detail/0',
    icon: 'icon-puzzle'
  }
  
  
];
