import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../environments/environment';
import { Usuario } from './../model/usuario';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
interface myData {
  success: boolean,
  message: string,
  message1: string,
  id_usuario: number,
  tipo_usuario: number
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private LoggedInStatus = false;
  private id_usuario;
  private tipo_usuario;

  constructor(private http: HttpClient) { }
  url = environment.base_Url1;
  
  setLoggedIn(value: boolean, id_usuario, tipo_usuario) {
    this.LoggedInStatus = value;
    this.id_usuario = id_usuario;
    this.tipo_usuario = tipo_usuario;
  }
  get getId() {
    return this.id_usuario;
  }
  get getType() {
    return this.tipo_usuario;
  }
  get isLoggedIn() {
    return this.LoggedInStatus;
  }

  
  getUsersDetails(usuario: Usuario) {
    return this.http.post<Usuario>(this.url +'/getauth', JSON.stringify(usuario));
  }
  registerUserService(username, password, nickname) {

    // tslint:disable-next-line:max-line-length
    return this.http.post<myData>(this.url + 'UsuariosQ.php', 'comando=insert&usuario=' + username + '&contrasena=' + password + '&nickname=' + nickname);
  }

}
