import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { Usuario } from '../../model/usuario';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent {
  constructor(private Auth: AuthService, private router: Router) { }
  @Input() usuario: Usuario;
  loginForm = new FormGroup({
    email: new FormControl(''),
    contrasena: new FormControl('')
  });
  ngOnInit() {
  }
  loginUser() {


    this.usuario = this.loginForm.value;
    this.Auth.getUsersDetails(this.usuario).subscribe(data => {
      if (data) {
        this.Auth.setLoggedIn(true, data[0].id_usuario, data[0].Tipo)
        if (data[0].Tipo === 7) {
          this.router.navigate(['/proveedores/hilazaredashboard'])
        } else if (data[0].Tipo === 2) {
          this.router.navigate(['/proveedores/hilazaredashboard'])
        } else if (data[0].Tipo === 10) {
          this.router.navigate(['/proveedores/datastudio'])
        } else if (data[0].Tipo === 3) {
          this.router.navigate(['/proveedores/datastudio'])
        } else {
          this.router.navigate(['/proveedores/terminadodashboard'])
        }
      } else {
        window.alert('Error en los datos suministrados');
      }
    });

  }

}
